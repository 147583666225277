body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cont {
  background-image: url("ktj background.png");
  height: 120vh;
  width: 130vw;
  background-repeat: no-repeat;
  border-radius: 0px;
  display: flex;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  position: fixed;
}




.ProfileDiv {
  height: 73.15vh;
  width: 80.5vw;
  left: 12.125vw;
  top: 20.316vh;
  border-radius: 1.8vw;
  background: #FFFFFF;
  opacity: 73%;
  position: absolute;
  box-shadow: 0px 2px 1.8vw 17px #00000040;
  background-blend-mode: multiply;
}

.ProfileSide {
  background: url('ProfileSide.png');
  height: 73.15vh;
  width: 29.8vw;
  left: 12.125vw;
  top: 20.316vh;
  border-top-left-radius: 1.8vw;
  border-bottom-left-radius: 1.8vw;
  position: absolute;
  opacity: 80%;
  background-blend-mode: multiply;
}

.ButtonAvatar {
  height: 30vh;
  width: 30vh;
  left: 20vw;
  top: 26.3vh;
  border-radius: 0.7vw;
  background: #F8F5F5;
  box-shadow: 4px 4px 4px 1px #00000040;
  position: absolute;
  background-image: fill;
  text-align: center;
  overflow: hidden;
}

.ButtonText {
  height: 2.64vh;
  width: 15vw;
  left: 19vw;
  top: 55vh;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 2.5vh;
  font-weight: 900;
  line-height: 2.636vh;
  letter-spacing: 0em;
  text-align: center;
  vertical-align: top;
  color: #0F2A70;
  position: absolute;

}

.NameOf {
  height: 5.9vh;
  width: 26.6vw;
  left: 13.65vw;
  top: 62.21vh;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 3.8vh;
  font-weight: 700;
  line-height: 4.7vh;
  letter-spacing: 0em;
  text-align: center;
  color: #01123ebf;
  position: absolute;
}

.HeadPro {
  height: 6.74vh;
  width: 21vw;
  left: 40vw;
  top: 23.6vh;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 4vh;
  font-weight: 600;
  line-height: 4.8vh;
  letter-spacing: 0em;
  text-align: center;
  color: #0F2A70;
  position: absolute;
}

.EditProB {
  height: 5.6vh;
  width: 9.03vw;
  left: 80.80vw;
  top: 22vh;
  border-radius: 0.7vw;
  background: #0F2A70BF 75%;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;


}

.EditBText {
  height: 4.8vh;
  width: 8.9vw;
  left: 84vw;
  top: 23.24vh;
  border-radius: nullpx;
  /* font-family: Montserrat; */
  font-size: 3vh;
  font-weight: 600;
  line-height: 3.22vh;
  letter-spacing: 0.1em;
  text-align: left;
  color: #FFFFFF;
  position: absolute;

}

.PLabel1 {
  height: 3.05vh;
  width: 1005;
  left: 44.9vw;
  top: 32.718vh;
  position: absolute;

}

.PLabel2 {
  height: 3.05vh;
  width: 100%;
  left: 69.2vw;
  top: 32.718vh;
  position: absolute;
}


.PLabel3 {
  height: 3.05vh;
  width: 8.855vw;
  left: 44.83vw;
  top: 42.5vh;
  position: absolute;
}

.PLabel4 {
  height: 3.05vh;
  width: 100%;
  left: 69.208vw;
  top: 42.5vh;
  position: absolute;
}

.PLabel5 {
  height: 3.05vh;
  width: 100%;
  left: 45.11vw;
  top: 52.25vh;
  position: absolute;
}

.PLabel6 {
  height: 3.05vh;
  width: 100%;
  left: 45.15vw;
  top: 62.8vh;
  position: absolute;
}

.PLabel7 {
  height: 3.05vh;
  width: 8.855vw;
  left: 45vw;
  top: 71.4vh;
  border-radius: nullpx;
  position: absolute;
}

.PLabel8 {
  height: 3.05vh;
  width: 8.855vw;
  left: 69.14vw;
  top: 71.4vh;
  position: absolute;
}

.PLabel9 {
  height: 3.05vh;
  width: 8.855vw;
  left: 44.55vw;
  top: 81.15vh;
  position: absolute;
}

.PLabel {
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 2.15vh;
  font-weight: 400;
  line-height: 2.636vh;
  letter-spacing: 0em;
  text-align: justified;
  color: #322929;

}

input {
  border-radius: 0.5vw;
  background: #D9D9D9;
  box-shadow: 0px 3px 3px 0px #817676;
  outline: none;
  border: none;
  font-size: 2.5vh;
  padding: 0 5px 5px 5px;
  stroke: solid #817676;
}

select {
  border-radius: 0.5vw;
  background: #D9D9D9;
  box-shadow: 0px 3px 3px 0px #817676;
  outline: none;
  border: none;
  font-size: 2.5vh;
  padding: 0 5px 5px 5px;
  stroke: solid #817676;
}

.PIn1 {
  height: 4.354vh;
  width: 18.15vw;
  left: 45vw;
  top: 36.23vh;
  position: absolute;
}

.PIn2 {
  height: 4.35vh;
  width: 18.125vw;
  left: 69vw;
  top: 36.234vh;
  position: absolute;
}

.PIn3 {
  height: 4.354vh;
  width: 18.144vw;
  left: 45vw;
  top: 45.511vh;
  position: absolute;
}

.PIn4 {
  height: 4.35vh;
  width: 18.125vw;
  left: 69vw;
  top: 45.511vh;
  border-radius: 0.5vw;
  position: absolute;
}

.PIn5 {
  height: 4.735vh;
  width: 42.3vw;
  left: 44.8vw;
  top: 55.27vh;
  border-radius: 0.5vw;
  position: absolute;
}

.PIn6 {
  height: 4.735vh;
  width: 42.3vw;
  left: 45vw;
  top: 65.5vh;
  border-radius: 0.5vw;
  position: absolute;
}

.PIn7 {
  height: 4.354vh;
  width: 18.143vw;
  left: 44.83vw;
  top: 74.416vh;
  border-radius: 0.5vw;
  position: absolute;
}

.PIn8 {
  height: 4.35vh;
  width: 18.125vw;
  left: 69vw;
  top: 74.416vh;
  border-radius: 0.5vw;
  position: absolute;
}

.PIn9 {
  height: 4.735vh;
  width: 42.3vw;
  left: 44.55vw;
  top: 83.84vh;
  border-radius: 0.5vw;
  position: absolute;
}

select {
  background: #F8F5F5;
  height: 4.735vh;
  width: 42.3vw;
  font-family: Montserrat;
  border-radius: 0.5vw;
  position: absolute;
  font-weight: 400;
  line-height: 2.636vh;
  letter-spacing: 0em;
  box-shadow: 0px 3.5668790340423584px 3.5668790340423584px 0px #00000040;
}

option {
  font-family: Montserrat;
  font-size: 2.6vh;
}




@media screen and (max-width:768px) {

  .PIn1,
  .PIn3,
  .PIn7,
  .PIn9 {
    left: 16vw;
    width: 32vw;
  }

  .PIn6,
  .PIn5 {
    left: 16vw;
    width: 68vw;
  }

  .PIn2,
  .PIn4,
  .PIn8 {
    left: 52vw;
    width: 32vw;
  }

  .ProfileSide {
    display: none;
  }

  .HeadPro {
    left: 15vw;
  }

  .PLabel1,
  .PLabel3,
  .PLabel5,
  .PLabel7,
  .PLabel9,
  .PLabel6 {
    left: 16vw;
    font-size: 2vh;
  }

  .PLabel2,
  .PLabel4,
  .PLabel8 {
    left: 52vw;
    font-size: 2vh;
  }

  .ButtonAvatar,
  .ButtonText {
    display: none;
  }

  .ProfileDiv {
    left: 10vw;

  }

  .EditBText {
    left: 67vw;
  }

  .EditProB {
    left: 65vw;
    width: 15vw;
  }
}

@media screen and (max-width: 370px) {
  .EditBText {
    font-size: 1.9vh;
  }
}