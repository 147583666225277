.card{
    display: flex;
    /* box-sizing: border-box; */
    border-radius: 0.5rem;
    /* grid-template-columns: 1fr; */
    /* grid-template-rows: 4fr repeat(4,1fr); */
    align-items: center;
    justify-items: center;
    background: white;
    text-align: center;
    padding: 2rem 4rem;
    transition: all 0.3s;
    z-index: 20;
    width: 40rem;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 3rem;
}

.card:hover{
    transform: translateY(-3.6rem) scale(1.01);
}


.name{
    font-size: 2.5rem;
    grid-row: 2/3;
    color: #4D4141;
}

.job{
    font-size: 2rem;
    grid-row: 3/4;
    color: #4D4141;
}

.icons{
    color: #4D4141;
    font-size: 3.5rem;
}

.num{
    font-size: 2rem;
    color: #4D4141;
}

.circle{
    /* box-sizing: border-box; */
    border-radius: 30rem;
    background: grey;
    background-position: center;
    background-size:cover;
    height: 15rem;
    width: 15rem;
    align-self: center;
    grid-row: 1/2;
}
.circle img{
    height: 15rem;
    width: 15rem;
    border-radius: 30rem;
}

.icons a{
    text-decoration: none;
    color: #4D4141;
}

@media (max-width: 1380px) {
    .card{
        width: 85%;
        margin: 1.4rem 0 1.4rem 0;
    }
  }