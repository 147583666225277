  
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

@font-face {
  font-family: Mplus;
  src: url("../../fonts/Mplus1p-Regular.otf");
}

@font-face {
  font-family: Mplus-Bold;
  src: url("../../fonts/\/Mplus1p-Bold.otf");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 /* font-family: "Jost", sans-serif;*/
  font-family: Mplus;
}

.logo span{
  font-family: Mplus-bold;
}

html {
  font-size: 63.4%;
}

a {
  text-decoration: none;
  cursor: pointer;
  
}

li a{
  font-size: 2.2rem;
}

li {
  list-style: none;
}
 

.main_nav {
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;

    background: #0f4d70;
    Fill: Solid
    rgba(15, 42, 112, 0.76);
  position: absolute;
  z-index: 40;
  position: fixed;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-size: 3.4rem;
   font-weight:520;
   margin-left: 1rem;
   width: 10%;
   color: white;
}
.logo img{
  height: 6.9rem ;
  width: 6.9rem;
}

.menu_link {
  width: 70%;
  margin-left: 0rem;
  padding-top: 2.4rem;
  transition: all 1s ease;
}

.menu_link ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 3.4rem;
  /* width: 80%; */
}
.menu_link .login{
  height: 3.5rem;
  width: 8rem;
  color: white;
  background-color: #083C59;
  font-size: 2rem;
  border-radius: 0.8rem;
  font-weight: 500;
  padding: 0.3rem;
  padding-bottom: 0.5rem;
  border: none;
}
.menu_link .sign{
  height: 3.5rem;
  min-width: 9rem;
  background-color: #083C59;
  color: yellow;
  font-size: 2rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  font-weight: 500;
  border: 1px solid white;
  border-radius: 0.3rem;
  font-weight: 500;
  padding-bottom: 0.2rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.sign{
  margin-right: 0rem;
  background-color: #083c59;
}
.menu_link ul li {
  display: flex;
  font-size: 2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.2em;
}
 

.menu_link ul li a {
  text-transform: capitalize;
  color: white;
  transition: 0.5s;
  
   
}
.menu_link ul li a:hover {
  transform-origin: left;
  color: rgb(230, 223, 28);
  /* text-decoration:  ; */
  transition: 0.5s;
}
.sign:hover{
  cursor: pointer;
  color:white;
  background-color: black;
  
}
.login:hover{
  cursor:pointer;
  color:black;
  background-color: white;
}
/* .button:hover .sign{
  cursor: pointer;
  color:white;
  background-color: black;
  
}
.button:hover .login{
  cursor:pointer;
  color:black;
  background-color: white;
} */
 

 .hamburger_menu{
  display: none;
  user-select: none;
 }
 

/* responsive css style  */
@media (max-width: 1250px) {
  .main_nav {
    height: 7.5rem;
    display: flex;
  }

  
  .menu_link ul {
    height: 7rem;
  }
  .logo{
    font-size:3rem;
    height: 7rem;
  }
}

@media (max-width: 1170px) {
  .main_nav {
    height: 7.3rem;
    display: flex;
  }

  
  .menu_link ul {
    height: 6.8rem;
    
  }
  .menu_link ul li a{
    font-size: 1.7rem;
  }
  .logo{
    font-size:2.8rem;
    height: 7.2rem;
  }
}
/* responsive css style  */
@media (max-width: 1088px) {
  .main_nav {
    height: 7rem;
    display: block;
    /* grid-template-columns: 2rem 2fr 3fr 2rem 2rem; */
  }

  .menu_link {
    display: none;
    transition: all 1s ease;
   
  }

  .logo{
    height: 7rem;
 
  }

  .mobile_menu_link {
 width: 100%;
 
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .mobile_menu_link {
    background-color: #17084ab9 ;
    height: 27rem;
    align-items: right;
    padding-bottom: 1.5rem;
    transition: all 1s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile_menu_link ul {
    height: 1.2rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3rem;
    margin-top: 11rem;
    z-index: 10;
    gap: 1px;

  }
  .mobile_menu_link ul li{
    margin-bottom: -1rem;
  }

  .mobile_menu_link ul li:first-child {
    transition-delay: 5s;
  }
  .hamburger_menu {
    transition: 1.5s;
    display: block;
    position: absolute;
    right: 2rem;
    top: 1.7rem;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main_nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo {
    height: 6rem;
  }
   .hamburger_menu {
    display: block;
    transition: 1s;
    font-size: 3.5rem;
    position: absolute;
    right: 1rem;
  }
}

@media (max-width: 520px) {
  .main_nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .mobile_menu_link{
    margin-top: 1rem;
  }
  .logo{
    height: 5rem;
  }

  .logo h2 {
    font-size: 2rem;
  }
   .hamburger_menu {
    transition: 1s;
    display: block;
    font-size: 3.5rem;
  }
 
}

.navbar_mobile_menu_link_nN0mM {
  color: red;
  transition: all 1s linear;
  animation: show 1s ease forwards;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
