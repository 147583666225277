.card{
    position: relative;
    display: flex;
    /* box-sizing: border-box; */
    border-radius: 0.5rem;
    /* grid-template-columns: 1fr; */
    /* grid-template-rows: 4fr repeat(4,1fr); */
    align-items: center;
    justify-items: center;
    background: white;
    text-align: center;
    transition: all 0.3s;
    position: relative;
    /* top:-50rem; */
    z-index: 20;
    width: 30rem;
    height: 18rem;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 3rem;
    margin-left: 3rem;
    margin-top: 7%;
}
.circle{
    /* box-sizing: border-box; */
    border-radius: 30rem;
    background: grey;
    background-position: center;
    background-size:cover;
    height: 18rem;
    width: 30rem;
    align-self: center;
    /* grid-row: 1/2; */
    /* overflow: hidden; */
}
.circle div{
    position: absolute;
    top: 0;
    opacity: 0;
    background-color:#22313b;
    width: 100%;
    height: 100%;
    font-family: monospace;
    font-size: 2.8rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    transition: all 0.3s;
}
.circle div:hover{
    opacity:0.9;
}
.circle span{
    letter-spacing: 0.2rem;
    display: flex;
    margin-top: 1.5rem;
    justify-content: center;
    align-items: center;
}
.circle ul{
    height: 40%;
    margin-top: -2rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items:flex-end;
}
ul a{
    text-decoration: none;
    margin: 0 auto;
    list-style: none;
}
 
a{
    color: white;
    opacity: 1;
}

.detail{
    height: 2rem;
    width: max-content;
    padding: 0.3rem 0.5rem;
    font-size: small;
    background-color: white;
    color: rgb(5, 58, 182);
    font-weight: 600;
    opacity: 1;
    border-radius: 1rem;
}
/* @media (max-width: 1659px){
     .card{
        margin-left: 12rem;
        width: 34rem;
        height: 21rem;
        margin-bottom: 3rem;
     }
     .circle{
        height: 21rem;
        width: 34rem;
    }

} */
@media (max-width: 1460px){
    .card{
       margin-left: 9rem;
       width: 34rem;
       height: 21rem;
    }
    .circle{
       height: 21rem;
       width: 34rem;
   }

}
@media (max-width: 1322px){
    .card{
        margin-left: 30%;
       margin-bottom: 5rem;
       width: 36rem;
       height: 22rem;
    }
    .circle{
       height: 22rem;
       width: 36rem;
   }

}
@media (max-width: 1086px){
    .card{
        margin-left: 30%;
       width: 36rem;
       height: 23rem;
    
    }
    .circle{
       height: 23rem;
       width: 36rem;
   }

}
@media (max-width:900px) {
    .card{
        margin-left: 30%;
        width: 34rem;
        height: 21rem;
        
    }
    .circle{
        height: 21rem;
        width: 34rem;
    }
    
}
@media (max-width:711px) {
    .card{
        margin-left: 10%;
        width: 34rem;
        height: 21rem;
        
    }
    .circle{
        height: 21rem;
        width: 34rem;
    }
    
}
@media (max-width:510px) {
    .card{
        margin-left: 1%;
        width: 32rem;
        height: 19rem;
        
    }
    .circle{
        height: 20rem;
        width: 32rem;
    }
    
}



